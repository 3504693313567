.content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.link {
    align-self: center;
    margin-bottom: 10px;
}
